<template>
  <div class="page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-cart</v-icon>
      تفاصيل الطلب
      <back-button />
    </h2>

    <p v-if="table.loading">
      <v-progress-linear
        indeterminate
        color="blue darken-2"
      ></v-progress-linear>
    </p>
    <table v-else class="table-info white">
      <tr>
        <td>
          <strong class="accent--text">order number</strong>
        </td>
        <td>{{ item.order_number }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">detail</strong>
        </td>
        <td>{{ item.details }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">address</strong>
        </td>
        <td>{{ item.address }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">bids count</strong>
        </td>
        <td>{{ item.bids_count }}</td>
      </tr>
      <tr>
        <tr>
        <td>
          <strong class="accent--text">lowest bid</strong>
        </td>
        <td>{{ item.lowest_bid }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">status</strong>
        </td>
        <td>{{ item.status_text }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">created at</strong>
        </td>
        <td>{{ new Date(item.created_at).toLocaleString("ar") }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">expire at</strong>
        </td>
        <td>{{ new Date(item.expire_at).toLocaleString("ar") }}</td>
      </tr>
      <!-- <tr>
        <td>
          <strong class="accent--text">اسم المستخدم</strong>
        </td>
        <td>{{ item.order_delivery_detail.name }}</td>
      </tr>

      <tr>
        <td>
          <strong class="accent--text">رقم الهاتف</strong>
        </td>
        <td>{{ item.order_delivery_detail.phone }}</td>
      </tr>

      <tr>
        <td>
          <strong class="accent--text">التقييم</strong>
        </td>
        <td>{{ item.service_rating }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">تكلفة التوصيل</strong>
        </td>
        <td>{{ item.delivery_cost }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">التكلفة بدون التوصيل</strong>
        </td>
        <td>{{ item.sub_total }}</td>
      </tr>

      <tr>
        <td>
          <strong class="accent--text">ملاحظة العميل</strong>
        </td>
        <td>{{ item.notes }}</td>
      </tr>

      <tr>
        <td>
          <strong class="accent--text">معاد انشاء الطلب</strong>
        </td>
        <td>{{ new Date(item.created_at).toLocaleString("ar") }}</td>
      </tr>

      <tr>
        <td>
          <strong class="accent--text">حالة الطلب</strong>
        </td>
        <td>{{ item.order_status.name }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">رسالة العميل عن الطلب</strong>
        </td>
        <td>{{ item.order_status.message }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">مجموع الحساب</strong>
        </td>
        <td>{{ item.total }} <span class="pr-1">ريــال</span></td>
      </tr> -->
    </table>

    <hr class="my-5 header-divider" />
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-cart</v-icon>
      order status
    </h2>

    <p v-if="table.loading">
      <v-progress-linear
        indeterminate
        color="blue darken-2"
      ></v-progress-linear>
    </p>
     <table v-else class="table-info white">
       <tr v-if="item.chef_rating != null">
        <td>
          <strong class="accent--text">chef rating</strong>
        </td>
        <td>{{ item.chef_rating.rating }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">image</strong>
        </td>
        <td><img :src="item.order_status.icon" width="100px" class="mt-3 mb-2 rounded-lg" /></td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">order rating status</strong>
        </td>
        <td>{{ item.order_status.message }}</td>
      </tr>
    <!--  <tr>
        <td>
          <strong class="accent--text">اسم المستخدم</strong>
        </td>
        <td>{{ item.order_delivery_detail.name }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">رقم هاتف المستخدم</strong>
        </td>
        <td>{{ item.order_delivery_detail.phone }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">وقت الوصول</strong>
        </td>
        <td>{{ item.order_delivery_detail.time }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">موقع المستخدم</strong>
        </td>
        <td>
          <a
            :href="
              location(
                item.order_delivery_detail.location.longitude,
                item.order_delivery_detail.location.latitude
              )
            "
          >
            map</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">تاريخ الوصول</strong>
        </td>
        <td>{{ item.order_delivery_detail.date }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">تقييم السائق</strong>
        </td>
        <td>{{ item.order_delivery_detail.driver_feed }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">تلميح الموقع</strong>
        </td>
        <td>{{ item.order_delivery_detail.hints }}</td>
      </tr>-->
    </table> 
    <hr class="my-5 header-divider" />
    <!-- CREATE / UPDATE FROM -->
    <!-- <record-form @update="fetchRecords()" /> -->
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-cart</v-icon>
     order bids
    </h2>

    <p v-if="table.loading">
      <v-progress-linear
        indeterminate
        color="blue darken-2"
      ></v-progress-linear>
    </p>
    <!-- <table v-else class="table-info white">
      <tr>
        <td>
          <strong class="accent--text">اسم السائق</strong>
        </td>
        <td>{{ item.order_delivery_detail.driver.name }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">الايميل</strong>
        </td>
        <td>{{ item.order_delivery_detail.driver.email }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">صورة السائق</strong>
        </td>
        <td>
          <img :width="50" :src="item.order_delivery_detail.driver.avatar" />
        </td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">رقم هاتف السائق</strong>
        </td>
        <td>
          {{ item.order_delivery_detail.driver.phone }}
        </td>
      </tr>
    </table> -->

    <hr class="my-5 header-divider" />
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="item.order_bids"
      loading-text="جاري تحميل البيانات"
      no-data-text="لا يوجد بيانات"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.avatar="{ item }">
        <img :src="item.chef.avatar" width="100px" class="mt-3 mb-2 rounded-lg" />
      </template>
      <template v-slot:item.subscription_end="{ item }">
        {{ new Date(item.chef.subscription_end).toLocaleString("ar") }}
      </template>
    </v-data-table>
    
    <!-- <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.address"
      :items="itemFromLoop"
      loading-text="جاري تحميل البيانات"
      no-data-text="لا يوجد بيانات"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >
    </v-data-table> -->
    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <!-- <v-pagination
        total-visible="7"
        circle
        :value="parseInt(filters.page)"
        :length="totalPages"
        @input="$router.replace({ query: { ...$route.query, page: $event } })"
      ></v-pagination> -->
    </div>
  </div>
</template>


<script>
// import RecordForm from './RecordForm';
import { mapState, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "orders",
  // components: { RecordForm },

  data: function() {
    return {
      user: {},
      item: {},
      id: this.$route.params.id,
      order: [],
      table: {
        headers: [
          {
            value: "price",
            text: "price"
          },
          {
            value: "accepted",
            text: "accepted"
          },
          {
            value: "chef.name",
            text: "chef name"
          },
           {
            value: "avatar",
            text: "chef image"
          },
          {
            value: "chef.display_name",
            text: "chef username"
          },
          {
            value: "chef.email",
            text: "chef email"
          },
          {
            value: "chef.gender",
            text: "chef gender"
          },
          {
            value: "chef.avg_rating",
            text: "avg rating"
          },
          {
            value: "chef.total_ratings",
            text: "total ratings"
          },
          {
            value: "chef.subscription_status",
            text: "subscription status"
          },
          {
            value: "chef.subscription_end",
            text: "subscription end"
          },
          // {
          //   value: "option.price",
          //   text: "السعر"
          // },
          // {
          //   value: "option.name",
          //   text: "الاسم"
          // },

          // {
          //   value: "qty",
          //   text: "الكمية"
          // },
          // {
          //   value: "name",
          //   text: "اسم الطلب"
          // },
          // {
          //   value: "total",
          //   text: "الاجمالى"
          // }
        ],
        address: [
          // {
          //   value: "price",
          //   text: "السعر"
          // },
          {
            value: "name",
            text: "الاسم"
          }
        ],
        loading: false
      },
      itemFromLoop: []
    };
  },

  computed: {
    ...mapState("orderdetails", [
      "records",
      "filters",
      "totalPages",
      "count",
      "totalPrice"
    ])
  },

  async created() {
    // this.fetchRecords();
    await axios
      .get("/dashboard/orders/details/" + this.id)
      .then(response => (this.item = response.data));
    // // console.log(data);
    // this.item = data;.
    ////// this.extextracta();
    // // console.log(this.itemFromLoop);
    // // console.log(this.item);
  },

  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }

        this.SET_FILTERS({ ...route.query });
        // this.fetchRecords();
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    location(x, y) {
      return "https://www.google.com/maps/@" + x + "," + y;
    },
    // extextracta() {
    //   this.itemFromLoop = this.item.order_line.map(ele => {
    //     return ele.extras;
    //   });
    //   this.itemFromLoop = this.itemFromLoop.flat();
    // },
    ...mapMutations(
      "orderdetails",
      [
        "SHOW_RECORD_FORM",
        "SET_EDIT_MODE",
        "SET_RECORD_FORM",
        "SET_FILTERS",
        "SET_COUNT",
        "SET_TOTAL_PRICE"
      ],
      "users",
      ["SHOW_FORM", "SET_EDIT_MODE", "SET_FORM_RECORD"]
    )

  }
};
</script>
